import React, {FC, memo, useState} from "react";
import {Chip} from "@material-ui/core";
import {useTkCart} from "../../../context/TkContext";
import './style.scss';
import TkModal, {TkModalActions, TkModalBody} from "../../particles/TkModal";
import TkSvgIcon from "../../particles/TkSvgIcon";

const TkCouponData: FC<{
    addedCoupon?: string
    size?: 'sm' | 'lg'
}> = ({addedCoupon, size = 'sm'}) => {
    const {applyCoupon, removeCoupon} = useTkCart();
    const [coupon, setCoupon] = useState('');
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleApplyCoupon = () => {
        setLoading(true)
        const isValidCoupon = /^[A-Z0-9]{2,}$/.test(coupon);
        if (!isValidCoupon) {
            setErrorMessage('O cupom deve ter pelo menos 2 caracteres e não pode conter caracteres especiais.');
            setShowErrorModal(true);
            setLoading(false);
            return;
        }

        applyCoupon(coupon).then(() => {
            setCoupon('');
        }).catch((error) => {
            setErrorMessage(error[0]?.message || 'Erro ao aplicar o cupom');
            setShowErrorModal(true);
        }).finally(() => {
            setLoading(false)
        });
    }

    const normalizeCharacters = (str: string) => {
        return str.normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ç/g, 'C').toUpperCase().replace(/[^A-Z0-9]/g, '');
    };

    return <>
        <div className="TkCouponData__container">
            {addedCoupon && (
                <Chip
                    label={addedCoupon}
                    onDelete={() => removeCoupon()}
                    size='small'
                    style={{borderRadius: 5, marginRight: 5, marginBottom: 5, fontWeight: 'bold'}}
                />
            )}
            {!addedCoupon && 
                <div className={`TkCouponData__input-container ${size} m-b-10px`}>
                    <div>
                        {loading ? <TkSvgIcon icon='sync-solid' className='rotate-1-seg'/> : <TkSvgIcon icon='cupom-desconto'/>}
                    </div>
                    <input
                        type='text'
                        className="form-control form-control-sm"
                        placeholder="Codigo de cupom"
                        value={coupon}
                        disabled={loading}
                        onChange={(e) => {
                            setCoupon(normalizeCharacters(e.target.value));
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleApplyCoupon();
                            }
                        }}
                        style={{flex: 1, margin: 0, padding: 0}}
                    />
                    <button
                        onClick={handleApplyCoupon}
                        disabled={loading}
                        style={{flexShrink: 0, margin: 0, padding: 0}}>
                        {loading ? 'Aguarde' : 'Aplicar'}
                    </button>
                </div>}
        </div>

        <TkModal
            show={showErrorModal}
            close={() => setShowErrorModal(false)}
            title="Falha no cupom"
            size="sm"
        >
            <TkModalBody>{errorMessage}</TkModalBody>
            <TkModalActions>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowErrorModal(false)}
                >
                Fechar
                </button>
            </TkModalActions>
        </TkModal>
    </>
}

export default memo(TkCouponData);