import React, {memo} from "react";
import "../TkModal/style.scss";

import TkModal, {TkModalActions, TkModalBody} from "../TkModal";
import {ITkProductModel} from "../../../models/product";

const TkConfirmHDRXModal: React.FC<{
  close(event?: any): void;
  onConfirm: Function;
  product: ITkProductModel;
  show: boolean;
}> = ({ close, onConfirm, product, show = false }) => {
  return (
    <TkModal show={show} title="Produto com entrega limitada" size="sm" close={close}>
      <TkModalBody>
        <span>{`O produto ${product.name} não possui disponibilidade para entrega ao seu endereço, sendo necessário retirar na loja.`}</span>
        <span>{" Tem certeza que deseja adicionar ao carrinho?"}</span>
      </TkModalBody>

      <TkModalActions>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onConfirm()}
        >
          Adicionar ao carrinho
        </button>
        <button type="button" className="btn btn-secondary" onClick={close}>
          Fechar
        </button>
      </TkModalActions>
    </TkModal>
  );
};

export default memo(TkConfirmHDRXModal);
