import './style.scss';
import React, {FC, lazy, ReactElement, Suspense, useCallback, useEffect, useState} from "react";
import TkHeadSEO from "../../components/particles/TkHeadSEO";
import TkHeader from "../../components/particles/TkHeader";
import TkBreadcrumb from "../../components/particles/TkBreadcrumb";
import TkNewsLetter from "../../components/user/TkNewsLetter";
import TkFooter from "../../components/particles/TkFooter";
import TkStepper from "../../components/particles/TkStepper";
import TkPaymentInitializer from "../../components/particles/TkPaymentInitializer";
import {useTkCart, useTkCheckout, useTkMedia, useTkUser} from "../../context/TkContext";
import TkProportionalImage from "../../components/particles/TkProportionalImage";
import {Link} from "react-router-dom";
import {tk_route_product_detail} from "../TkProductDetailView";
import {formatMoney} from "../../utils/number-utils";
import DeviceStorage from "../../utils/storage-utils";
import {ITkCart, ITkProductCartAvailability} from "../../models/cart";
import TkSkeleton from "../../components/particles/TkSkeleton";
import {isBlank} from "../../utils/string-utils";
import TkCouponData from "../../components/cart/TkCouponData";
import {UserRole} from "../../models/user";

const NoPhoto = 'https://teky.s3.sa-east-1.amazonaws.com/no-photo.svg';

const PersonalData = lazy(() => import(/* webpackChunkName: "checkout_personal" */ /* webpackPrefetch: true */ "./_personalData"));
const CompanyData = lazy(() => import(/* webpackChunkName: "checkout_company" */ /* webpackPrefetch: true */ "./_companyData"));
const AddressData = lazy(() => import(/* webpackChunkName: "checkout_address" */ /* webpackPrefetch: true */ "./_addressData"));
const PaymentData = lazy(() => import(/* webpackChunkName: "checkout_payment" */ /* webpackPrefetch: true */ "./_paymentData"));
const CouponData = lazy(() => import(/* webpackChunkName: "checkout_coupon" */ /* webpackPrefetch: true */ "./_couponData"));
const FinishOrder = lazy(() => import(/* webpackChunkName: "checkout_finish" */ /* webpackPrefetch: true */ "./_finishOrder"));


export const tk_route_checkout = '/confirmacao-dados-para-compra';

const PricePlaceholder: FC<{
  isShowSkeleton: boolean
  skeletonWidth?: number
  label: ReactElement
  value: ReactElement
}> = ({isShowSkeleton, skeletonWidth = 100, label, value}) => {

  if (isShowSkeleton) return <div className='t-a-r'>
    <TkSkeleton width={skeletonWidth} height={20} style={{display: 'inline-block', margin: 0}}/>
  </div>

  return <div className='t-a-r'>{label}: {value}</div>
}

const CartInfo: FC<{
  currentCart?: ITkCart,
}> = ({ currentCart }) => {
  const { isAuth, userAuth, hasRole } = useTkUser();
  const { checkoutCosts, paymentMethod, isCalculatingCheckoutCosts } = useTkCheckout();
  const [showItems, setShowItems] = useState(false);
  const [lastPostalCode, setLastPostalCode] = useState(null);
  const [isCouponCustomerAgreement, setIsCouponCustomerAgreement] = useState(false);

  useEffect(() => {
    const checkRole = async () => {
      const result = await hasRole(UserRole.COUPON_CUSTOMER_AGREEMENT);
      setIsCouponCustomerAgreement(result);
    };

    checkRole();
  }, [hasRole]);

  useEffect(() => {
    const postalCode = userAuth?.user?.defaultAddress?.postalCode || DeviceStorage.getPostalCode;
    if (postalCode !== lastPostalCode && isAuth() && postalCode) {
      setLastPostalCode(postalCode);
    }
  }, [lastPostalCode, setLastPostalCode, userAuth, isAuth]);

  const shipmentInfo = () => {
    const hasShippingItem = currentCart.items.some(i => !i.isWithdraw);

    const backorderShipmentCost = checkoutCosts.backorderShipment?.value || 0;
    const totalShippingCost = checkoutCosts.shipmentCost + backorderShipmentCost;
    if (hasShippingItem && totalShippingCost) {
      if (isCalculatingCheckoutCosts) {
        return <PricePlaceholder isShowSkeleton={true} label={null} value={null} />;
      }

      return <div className='t-a-r'>Frete: <b>{formatMoney(totalShippingCost)}</b></div>;
    }

    return null;
  };

  if (!currentCart || !currentCart.items || currentCart.items.length === 0) return <></>;

  return (
      <div className={`resume ${showItems && 'open'}`}>
        <h2>Resumo do Carrinho</h2>
        <ul>
          {currentCart.items.map((item, index) => (
              <li key={index}>
                <Link to={tk_route_product_detail(item.product)} title='Ver detalhes do produto'>
                  <TkProportionalImage
                      className='TkMyCartView__img'
                      url={!!item.product?.thumbnail ? item.product?.thumbnail : NoPhoto}
                      plainB={NoPhoto}
                  />
                </Link>
                <div>
                  <h3>{item.product.name}</h3>
                  <p>
                    <span>Preço unitário <ins>{formatMoney(item.price)}</ins></span>
                    <span>Subtotal (item) <ins>{formatMoney(item.total)}</ins></span>
                  </p>
                </div>
              </li>
          ))}
        </ul>
        {currentCart?.items?.length > 2 && (
            <button type="button" onClick={() => setShowItems(!showItems)}>
              {!showItems ? `Ver mais (${(currentCart.items.length - 2)}) items` : `Ver menos (${(currentCart.items.length - 2)}) items`}
            </button>
        )}

        <div className='t-a-r m-t-30px'>Subtotal (carrinho): <b>{currentCart && formatMoney(currentCart.totalValue)}</b></div>
        {!isBlank(currentCart.coupon) ? (
            <>
              <PricePlaceholder
                  isShowSkeleton={isCalculatingCheckoutCosts}
                  skeletonWidth={150}
                  label={<span>Desconto Cupom</span>}
                  value={<b>- {formatMoney(currentCart.couponSavings)}</b>}
              />
            </>
        ) : null}
        {paymentMethod === 'credit_card' &&
            <PricePlaceholder
                isShowSkeleton={isCalculatingCheckoutCosts}
                skeletonWidth={180}
                label={<span>Taxas financeiras</span>}
                value={<b>{formatMoney(checkoutCosts.totalFees)}</b>}
            />
        }
        {shipmentInfo()}
        <PricePlaceholder
            isShowSkeleton={isCalculatingCheckoutCosts}
            skeletonWidth={150}
            label={<b>Total geral</b>}
            value={<b className='c-p-red'>{formatMoney(checkoutCosts.totalWithFees)}</b>}
        />

        {!isCouponCustomerAgreement ?
          <div className="m-t-20px">
            <TkCouponData addedCoupon={currentCart?.coupon} />
          </div> : null
        }
      </div>
  )
}

const AvailabilityCard: FC<{
  availability: ITkProductCartAvailability
  cart: ITkCart
}> = ({availability, cart}) => {

  if (!availability || availability.success || !cart?.items) return <></>

  const suffix1 = availability.result.length >= 2 ? 'ns' : 'm'
  const suffix2 = availability.result.length >= 2 ? 's' : ''
  const suffix3 = availability.result.length >= 2 ? 'ram' : 'u'

  return <div className='TkCheckoutView__msg-alerts'>
    <h4>Mensage{suffix1} importante{suffix2} para ite{suffix1} em seu carrinho:</h4>
    <small>
      <b>{availability.result.length} ite{suffix1} em seu carrinho de compras sofre{suffix3} alteração de
        disponibilidade de estoque.</b><br/>
      Os ite{suffix1} no seu carrinho de compras sempre irão refletir o estoque mais recente exibido nas páginas
      de detalhes dos produtos.
    </small>
    <ul>
      {availability.result.map((r, idx) => {
        return <li key={idx}><Link to={tk_route_product_detail(r.product)}>{r.product.name}</Link> estoque
          disponível <span className='c-p-red f-w-b'>{r.product.price > 0 ? r.currentAvailability : 0}</span></li>
      })}
    </ul>
  </div>
}

const TkCheckoutView: FC = () => {
  const {isTypeBusiness, isAuth} = useTkUser();
  const {currentCart, validateProductsAvailability, percentCartWithdraw} = useTkCart();
  const {userAuth, hasRole} = useTkUser()
  const {calculateCosts,} = useTkCheckout()
  const {isMediaXs} = useTkMedia();
  const [activeIndex, setActiveIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(-1);
  const [labels, setLabels] = useState<string[]>([]);
  const [components, setComponents] = useState<any[]>([]);
  const [availability, setAvailability] = useState<ITkProductCartAvailability>(null);
  const [isCouponCustomerAgreement, setIsCouponCustomerAgreement] = useState(false);

  useEffect(() => {
    const checkRole = async () => {
      const result = await hasRole(UserRole.COUPON_CUSTOMER_AGREEMENT);
      setIsCouponCustomerAgreement(result);
    };

    checkRole();
  }, [hasRole]);

  useEffect(() => {
    if (isAuth()) {
      const tmpLabels = ['Dados Pessoais']
      const tmpComps = [<PersonalData next={next} isInOrOut={activeIndex > lastIndex}
                                      buttonDisabled={!availability?.success}/>]

      if (isTypeBusiness()) {
        tmpLabels.push('Empresa')
        tmpComps.push(<CompanyData next={next} back={back} isInOrOut={activeIndex > lastIndex}
                                   buttonDisabled={!availability?.success}/>)
      }

      let hasNotWithdraw = percentCartWithdraw() === 0

      if (isCouponCustomerAgreement) {
        tmpLabels.push(`Endereço${hasNotWithdraw ? '' : ' de cadastro'}`, 'Cupom', 'Finalizar')
      } else {
        tmpLabels.push(`Endereço${hasNotWithdraw ? '' : ' de cadastro'}`, 'Pagamento', 'Finalizar')
      }

      tmpComps.push(
          <AddressData next={next} back={back} isInOrOut={activeIndex > lastIndex} isCouponUser={isCouponCustomerAgreement}/>,
          isCouponCustomerAgreement ?
              <CouponData back={back} next={next} addedCoupon={currentCart?.coupon}/> :
              <PaymentData next={next} back={back} isInOrOut={activeIndex > lastIndex} />,
          <FinishOrder />
      );

      setLabels(tmpLabels)
      setComponents(tmpComps)
    }
  }, [isTypeBusiness, isAuth, availability, currentCart, isCouponCustomerAgreement])

  useEffect(() => {
    if (currentCart?.totalValue > 0) {
      (async () => {
        const request = await validateProductsAvailability()
        if (request.success) setAvailability(request.data)
      })()
    }
  }, [currentCart, validateProductsAvailability, setAvailability]);

  useEffect(() => {
    if (!userAuth?.user) return
    const postalCode = userAuth?.user?.defaultAddress?.postalCode || DeviceStorage.getPostalCode
    if (!isBlank(postalCode)) calculateCosts(postalCode, 'pix', 1)
  }, [calculateCosts, userAuth])

  function next() {
    setLastIndex(activeIndex);
    setActiveIndex(idx => (idx + 1) % labels.length);
  }

  function back() {
    setLastIndex(activeIndex);
    setActiveIndex(idx => (idx - 1) % labels.length);
  }

  const getForm = useCallback(() => components[activeIndex], [components, activeIndex])

  return <>
    <TkHeadSEO title='Confirmação dados do pedido | Teky'/>

    <TkHeader/>

    <div className="TkCheckoutView m-t-1rem">
      <TkBreadcrumb list={[
        {
          url: tk_route_checkout,
          label: 'Confirmação Dados do Pedido'
        }
      ]}/>

      {activeIndex === 3 ? <></> : <h1 className='t-a-c m-t-30px'>Confira e finalize seu pedido</h1>}

      <AvailabilityCard availability={availability}
                        cart={currentCart}/>

      <div className={`TkCheckoutView__container Step_${activeIndex} m-t-20px`}>
        <div className="steppers">
          {isMediaXs && <Suspense fallback={<div>Carregando...</div>}>{getForm()}</Suspense>}
          {currentCart?.items && <TkStepper labels={labels} activeIndex={activeIndex}/>}
          {!isMediaXs && <Suspense fallback={<div>Carregando...</div>}>{getForm()}</Suspense>}
        </div>
        <CartInfo currentCart={currentCart}/>
      </div>
    </div>

    <TkNewsLetter/>
    <TkFooter/>
    <TkPaymentInitializer/>
  </>
};

export default TkCheckoutView